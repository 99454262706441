import React from "react"

import Body from '../components/layout/default/main'
import TechdocList from '../components/widgets/techdoclist/techdoclist'

export default () => {
    return (<Body>
        <h1>Tech Docs</h1>
        <div>
            <TechdocList />
        </div>
    </Body>)
}
